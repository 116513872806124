<template>
  <ElFormItem
    :label="locale.label"
    prop="advertiser"
  >
    <ElSelect
      :placeholder="locale.placeholder"
      :value="value"
      :disabled="!holding"
      value-key="id"
      @input="$emit('input', $event)"
      :no-data-text="locale.noData"
      filterable
    >
      <ElOption
        v-for="item in advertisers"
        :key="item.id"
        :label="item.title"
        :value="item"
      />
    </ElSelect>
  </ElFormItem>
</template>

<script>
import ElFormItem from 'element/ElFormItem'
import ElOption from 'element/ElOption'
import ElSelect from 'element/ElSelect'

export default {
  components: {
    ElFormItem,
    ElSelect,
    ElOption,
  },

  props: {
    value: VueTypes.oneOfType([VueTypes.object, VueTypes.nullType]),
    holding: VueTypes.oneOfType([VueTypes.object, VueTypes.nullType]),
  },

  watch: {
    holding: {
      handler () {
        this.$emit('input', null)
      },
      deep: true,
    },
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('settings.form.advertiser.label'),
      placeholder: $locale('settings.form.advertiser.placeholder'),
      noData: $locale('settings.form.advertiser.noData'),
    }),
    advertisers ({ holding, holdings }) {
      if (holding && holdings) {
        return holdings.find(item => item.id === holding.id).advertisers
      }
      return []
    },
    holdings: ({ $store }) => $store.getters['dictionaries/campaignDictionaries'].holdings,
  },
}
</script>
